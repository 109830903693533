import React, { useEffect, useContext, useState, forwardRef } from "react";
import { AuthContext, IAuthContext } from "react-oauth2-code-pkce";
import { creationAPI, versionsAPI } from "../api/TimestampAPI";
import { CRUD } from "../Constants";
import CreateUpdateProjectDialog from "./CreateUpdateProjectDialog";
import {
  Button,
  TextField,
  Typography,
  Box,
  LinearProgress,
  MenuItem,
  FormControl,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
  DialogContentText,
  FormHelperText,
  Divider,
  InputAdornment,
  Snackbar,
} from "@mui/material";
import { getUploadTypes } from "../Constants";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import CreationTypeIcon from "../components/CreationTypeIcon";
import FileUploadSelectParent from "../components/FileUploadSelectParent";
import { useTranslation } from "react-i18next";
import { hashExists } from "../api/files";
import { useQueryClient } from "@tanstack/react-query";

interface CreateCreationProps {
  onClose: () => void;
  onSubmit: () => void;
  open?: boolean;
  action?: string;
  selectedFile?: any;
  path?: any;
  projectId?: any;
  creationId?: any;
}


const allowedFileTypes = [
  'text/plain', // .txt
  'application/msword', // .doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'application/pdf', // .pdf
  'audio/mpeg', // .mp3
  'audio/flac', // .flac
  'audio/x-flac', // .flac
  'audio/mp4', // .m4a/aac
  'audio/m4a', // .m4a/aac
  'audio/x-m4a', // .m4a/aac
  'audio/aac', // .m4a/aac
  'audio/x-aac', // .m4a/aac
  'audio/wav', // .wav
  'audio/x-wav', // .wav
  'audio/wave', // .wav
  'audio/vnd.wav', // .wav
  'audio/vnd.wave', // .wav
  'audio/ogg', // .ogg
  'video/mp4', // .mp4
  'video/quicktime', // .mov
  'image/jpeg', // .jpg
  'image/png', // .png
  'image/x-png' // .png
];


const UploadCreationVersion = forwardRef<HTMLDivElement, CreateCreationProps>(
  (
    {
      open,
      onClose,
      onSubmit,
      selectedFile,
      creationId,
      projectId,
    },
    ref
  ) => {
    var { tokenData, idTokenData } = useContext<IAuthContext>(AuthContext);

    tokenData = idTokenData || tokenData;
    const theme = useTheme();
    const uploadTypes = getUploadTypes(selectedFile?.name || "");

    const [creationName, setCreationName] = useState(selectedFile?.name);
    const [creationDescription, setCreationDescription] = useState("");
    const [count, setCount] = useState(0);
    const [uploadType, setUploadType] = useState(uploadTypes[0]);
    const [hasherProgress, setHasherProgress] = useState(0);
    const [isHashing, setIsHashing] = useState(true);
    const [contentHash, setContentHash] = useState("");
    const [openCreateProject, setCreateProject] = useState(false);
    const [alert, setAlert] = useState("");
    const [openFileUploadAlert, setOpenFileUploadAlert] = useState(false);
    const [existingCreationVersion, setExistingCreationVersion] = useState({} as any);

    const navigate = useNavigate();
    const { t } = useTranslation();
    const queryClient = useQueryClient()


    const file_already_exists_text = t('upload_creation_form.upload_error_already_exists');

    const handleCreateCreation = async (
      creationName: string,
      uploadType: string,
      contentHash: string
    ) => {
      // TODO: Clean this up
      if (creationId) {
        const resp = await versionsAPI.create(
          creationId,
          creationName,
          creationDescription,
          projectId,
          uploadType,
          selectedFile,
          contentHash
        )
        if (resp.status == 400 && resp.data.errors[0] == "Creation Version for file already exists: ") {
          setAlert(file_already_exists_text);
        } else if (resp.status !== 201) {
          setAlert(t("unexpected_error"));
        } else {
          onSubmit()
          setAlert("")
          const versions = await versionsAPI.get(creationId);
          const newVersionNo = versions[versions.length - 1].version;
          queryClient.invalidateQueries({ queryKey: ['creations'] });
          navigate(`/projects/${projectId}/${creationId}/${newVersionNo}`);
        }
      } else {
        const resp = await creationAPI.create(
          creationName,
          creationDescription,
          projectId,
          uploadType,
          selectedFile,
          contentHash
        )
        if (resp.status == 400 && resp.data.errors[0] == "Creation Version for file already exists: ") {
          setAlert(file_already_exists_text);
        } else if (resp.status !== 201) {
          setAlert(t("unexpected_error"));
        } else {
          onSubmit()
          setAlert("")
          const newCreationId = resp.data.creation_id;
          queryClient.invalidateQueries({ queryKey: ['creations'] });
          navigate(`/projects/${projectId}/${newCreationId}/1`);
        }
      }


    };

    const handleClose = () => {
      setCreationDescription("");
      setAlert("")
      onClose();
    }

    const handleSubmit = () => {
      handleCreateCreation(creationName, uploadType, contentHash);
    };

    const creationDescriptionChangeHandler = (e: any) => {
      setCreationDescription(e.target.value);
      setCount(e.target.value.split("").length);
    };

    const handleCloseCreateProjectModal = () => {
      setCreateProject(false);
    };


    useEffect(() => {
      (async () => {
        console.log("Selected File Type:", selectedFile?.type)
        if (selectedFile === null) {
          setIsHashing(false);
        } else {
          if (selectedFile.size > 100000000) { // 100MB
            setAlert(t('upload_creation_form.upload_error_file_too_large'));
            return;
          } else if (!allowedFileTypes.includes(selectedFile.type)) {
            setAlert(t('upload_creation_form.upload_error_file_type_not_allowed'));
            return;
          }
          setCreationName(selectedFile?.name);
          setUploadType(uploadTypes[0]);
          const worker = new Worker('/webworkers/hasher.worker.js');

          worker.onmessage = (event) => {
            if (typeof (event.data) === "object") {
              setContentHash(event.data.hash);
              setIsHashing(false);
              // Check if exists
              hashExists(event.data.hash).then((exists) => {
                if (exists) {
                  handleClose();
                  setOpenFileUploadAlert(true);
                  setExistingCreationVersion(exists);
                }
              });
            } else {
              setHasherProgress(event.data)
            }
          }

          worker.postMessage(selectedFile);
          setIsHashing(true);
        }
      })();
    }, [selectedFile]);
    return (
      <>
        {/* Modal Box for "Create new Folder" */}
        <CreateUpdateProjectDialog
          open={openCreateProject}
          onClose={handleCloseCreateProjectModal}
          action={CRUD.Create}
        />

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={openFileUploadAlert}
          onClose={() => setOpenFileUploadAlert(false)}
        >
          <Alert
            onClose={() => setOpenFileUploadAlert(false)}
            severity="warning"
            // variant="filled"
            sx={{ width: '100%' }}
          >
            <Stack direction="column" spacing={3}>
              {file_already_exists_text}
              <Button
                // variant="outlined"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenFileUploadAlert(false);
                  navigate(`/projects/${existingCreationVersion?.creation?.project_id}/${existingCreationVersion?.creation_id}/${existingCreationVersion?.version}`);
                }}
              >{existingCreationVersion?.creation?.project?.name} - {existingCreationVersion?.name}</Button>
            </Stack>
          </Alert>
        </Snackbar>

        {/* Main pop-up for creation or version */}
        <Dialog open={open} onClose={handleClose} fullWidth={true} scroll={"paper"}>
          {/* <Box component="form" onSubmit={handleSubmit}> */}
          <DialogTitle>{t('upload_creation_form.title')}</DialogTitle>
          <DialogContent dividers>
            <DialogContentText>
              <Box>
                <Typography variant="subtitle1" fontWeight={600} color={theme.palette.primary.main} sx={{ mb: 2 }}>
                  {t('upload_creation_form.headers.uploaded_file_details')}
                </Typography>
                <Typography variant="body2">
                  <strong>{t('filename')}<br /></strong> {selectedFile?.name}
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  <strong>{t('file size')}<br /></strong> {selectedFile?.size < 1024
                    ? `${selectedFile?.size} B`
                    : selectedFile?.size < 1048576
                      ? `${(selectedFile?.size / 1024).toFixed(2)} KB`
                      : `${(selectedFile?.size / 1048576).toFixed(2)} MB`}
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}><strong>{t('file hash')}<br /></strong></Typography>{isHashing ?
                  <LinearProgress variant="determinate" value={hasherProgress} sx={{ mt: 2 }} />
                  :
                  <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>{contentHash}</Typography>
                }

              </Box>
              <Divider sx={{ my: 2 }} />
              <Stack gap={4}>
                <Box>
                  <Typography variant="subtitle1" fontWeight={600} color={theme.palette.primary.main} sx={{ mb: 2 }}>
                    {t('upload_creation_form.headers.creation_details')}
                  </Typography>
                  <TextField
                    autoFocus
                    placeholder={selectedFile?.name}
                    id="file-name"
                    fullWidth
                    value={creationName}
                    label={t('upload_creation_form.fields.creation_name_label')}
                    helperText={t('upload_creation_form.fields.creation_name_helpertext')}
                    onChange={(e) => setCreationName(e.target.value)}
                  />
                </Box>

                {/* Upload Type */}
                <Box>
                  <FormControl>
                    <TextField
                      select
                      defaultValue={uploadTypes[0]}
                      id="upload-type"
                      value={uploadType}
                      label={t('upload_creation_form.fields.creation_type_label')}
                      onChange={(e) => setUploadType(e.target.value)}
                    >
                      {uploadTypes.map((type) => (
                        <MenuItem key={type} value={type}>
                          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <CreationTypeIcon creationType={type} />{t(type)}
                          </Box>
                        </MenuItem>
                      ))}
                    </TextField>
                    <FormHelperText>{t('upload_creation_form.fields.creation_type_helpertext')}</FormHelperText>
                  </FormControl>
                </Box>

                <Box>
                  <TextField
                    id="description"
                    multiline
                    rows={4}
                    fullWidth
                    value={creationDescription}
                    onChange={creationDescriptionChangeHandler}
                    label={t('upload_creation_form.fields.creation_description_label')}
                    helperText={t('upload_creation_form.fields.creation_description_helpertext')}
                    inputProps={{ maxLength: 250 }}
                    InputProps={{
                      endAdornment: <InputAdornment variant="filled" position="end" sx={{}}>
                        <Typography fontSize="small" sx={{ color: "rgba(172, 172, 185, 1)" }}>
                          {`${count}/250`}
                        </Typography>
                      </InputAdornment>
                    }}
                  />
                </Box>
              </Stack>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ p: 3 }}>
            <Stack sx={{ width: '100%' }}>
              {alert &&
                <Box>
                  <Alert severity="error" sx={{ mb: 2, borderRadius: 3 }}>{alert}</Alert>
                </Box>
              }
              <Stack direction={"row"} spacing={2} justifyContent={"flex-end"}>
                <Button variant="outlined" onClick={handleClose}>
                  {t('cancel')}
                </Button>
                <Button
                  onClick={handleSubmit}
                  disabled={alert ? true : false || isHashing}
                  type="submit"
                  variant="contained"
                >
                  {t('upload_creation_form.buttons.upload')}
                </Button>
              </Stack>
            </Stack>
          </DialogActions>
          {/* </Box > */}
        </Dialog >
      </>
    );
  }
);

export default UploadCreationVersion;