import React from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import TxtDisplay from "./FileDisplay/TxtDisplay";
import DocViewer from "./FileDisplay/DocViewer";
import { Box } from "@mui/material";
import { API_ENDPOINT } from "../Settings";
import VideoPlayer from "./FileDisplay/VideoPlayer";
import VideoJS from "./FileDisplay/VideojsPlayer";

const extentionsToMimeType = {
  ".aac": "audio/x-aac",
  ".doc": "application/msword",
  ".docx": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ".flac": "audio/flac",
  ".jpeg": "image/jpeg",
  ".jpg": "image/jpeg",
  ".jpgm": "video/jpm",
  ".jpgv": "video/jpeg",
  ".m4a": "audio/x-m4a",
  ".mov": "video/quicktime",
  ".mp3": "audio/mpeg",
  ".mp4": "video/mp4",
  ".mp4a": "audio/mp4",
  ".mp4v": "video/mp4",
  ".mpa": "video/mpeg",
  ".mpe": "video/mpeg",
  ".mpeg": "video/mpeg",
  ".mpg": "video/mpeg",
  ".mpg4": "video/mp4",
  ".mpga": "audio/mpeg",
  ".oga": "audio/ogg",
  ".ogg": "audio/ogg",
  ".ogv": "video/ogg",
  ".pdf": "application/pdf",
  ".txt": "text/plain",
  ".wav": "audio/x-wav",
};

interface FileDisplayProps {
  url: string;
  fileExtention: string;
  mimeType?: string;
  style?: React.CSSProperties;
  fileName?: string;
  createdBy?: string;
  getUpdatedFileDownloadUrl?: () => any
}

const FileDisplay: React.FC<FileDisplayProps> = ({
  url,
  fileExtention,
  mimeType,
  style,
  fileName,
  createdBy,
  getUpdatedFileDownloadUrl
}) => {
  if (url.startsWith("/")) {
    url = API_ENDPOINT + url;
    mimeType = new URL(url).searchParams.get("content_type") || "";
  }
  // File extension is only set after file has been processed in background. We can use extension from url to get the file extension if it is not set.
  if (!fileExtention) {
    // get extension from url, ignore query params
    fileExtention = url.split("?")[0].split(".").pop() || "";
  }
  if (!mimeType) {
    mimeType = extentionsToMimeType["." + fileExtention];
  }
  switch (mimeType) {
    case "application/pdf":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return (
        <DocViewer url={url} />
      )

    case "audio/mpeg":
    case "audio/wav":
    case "audio/x-wav":
    case "audio/wave":
    case "audio/vnd.wav":
    case "audio/vnd.wave":
    case "audio/mp3":
    case "audio/ogg":
    case "audio/flac":
    case "audio/x-flac":
    case "audio/x-aac":
    case "audio/x-m4a":
    case "audio/mp4":
    case "audio/mp4a":
    case "audio/aac":
    case "audio/x-aac":
    case "video/3gpp": // Libmagic returns video/3gpp for this m4a file
      return (
        <AudioPlayer
          src={url}
          autoPlay={false}
          autoPlayAfterSrcChange={false}
          customVolumeControls={[]}
          customAdditionalControls={[
            <h5
              style={{
                fontSize: "24px",
                fontWeight: "600",
                display: "block",
                margin: "0",
                textAlign: "left",
              }}
            >
              {fileName}
            </h5>,
            <p style={{ color: "rgba(92, 92, 111, 1)", margin: "10px 0px" }}>
              {createdBy}
            </p>,
          ]}
          showJumpControls={true}
        />
      );
    case "text/plain":
      return <TxtDisplay url={url} />
    case "video/mp4":
    case "video/quicktime":
      return (
        <div style={{
          background: "white",
          width: "100%",
          // height: "80vh",
        }}>
          <VideoJS src={url} getUpdatedFileDownloadUrl={getUpdatedFileDownloadUrl} contentType={mimeType} />
        </div>
      );
    default:
      return (
        <embed
          type={mimeType}
          src={url}
          style={{
            ...style,
            background: "white",
            height: "80vh",
            width: "100%",
          }}
        />
      );
  }
};

export default FileDisplay;
